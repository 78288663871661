import $axios from '@/utils/request'

// 获取训练课程列表
export function getTrainCourseList(data) {
    const url = '/tarin/course/list'
    return $axios.fPost(url, data)
}
// 添加训练课程
export function addTrainCourse(data) {
    const url = '/tarin/course/add'
    return $axios.fGet(url, data)
}
// 编辑训练课程
export function editTrainCourse(data) {
    const url = '/tarin/course/edit'
    return $axios.fPost(url, data)
}

/**
 * 获取课程下员工
 * @param {*} data 
 * @returns 
 */
export function getEmployeeList(data) {
    const url = '/user/listEmployee'
    return $axios.fPost(url, data)
}
/**
 * 分配课程下员工
 * @param {*} data 
 * @returns 
 */
export function batchCourseUser(data) {
    const url = '/course/user/batchCourseUser'
    return $axios.fPost(url, data)
}