import $axios from '@/utils/request'

// 获取系统用户列表
export function getSysAccountList(data) {
    const url = '/systemAccount/getSystemAccountList'
    return $axios.fGet(url, data)
}
// 添加系统用户
export function addSysAccount(data) {
    const url = '/systemAccount/addSystemAccount'
    return $axios.fPost(url, data)
}
// 编辑系统用户
export function editSysAccount(data) {
    const url = '/systemAccount/editSystemAccount'
    return $axios.fPost(url, data)
}

// 编辑系统用户状态
export function editSysAccountStatue(data) {
    const url = '/systemAccount/forbiddenSystemAccount'
    return $axios.fPut(url, data)
}

// 编辑系统用户状态
export function editSysAccountPwd(data) {
    const url = '/user/editPwdOt'
    return $axios.fPost(url, data)
}