<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    height="90%"
    top="7vh"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>选择学员</span>
    </div>

    <el-row :gutter="60">
      <el-col :span="14">
        <el-input
          placeholder="请输入内容"
          v-model="inputVal"
          style="width: 420px; margin-bottom: 10px"
          @keyup.enter.native="search"
        >
          <el-select
            v-model="select"
            slot="prepend"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option label="姓名" value="1"></el-option>
            <el-option label="用户名" value="2"></el-option>
          </el-select>
        </el-input>
        <el-button size="small" @click="search" type="primary" class="ml-15"
          >查 询</el-button
        >

        <el-table
          style="margin-top: 15px"
          :data="tableData"
          border
          fit
          highlight-current-row
          @select="handleSelection"
          @selection-change="handleSelectionChange"
          ref="teaRef"
        >
          <el-table-column
            type="selection"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column label="用户名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.loginName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
      <el-col :span="10">
        <el-row
          style="
            box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
            padding-bottom: 30px;
          "
        >
          <el-row class="seleRow">
            <span>已选择：</span>
            <span class="seleNum">{{ multipleSelectionAll.length }}</span>
          </el-row>
          <ul
            v-for="(item, index) of multipleSelectionAll"
            :key="index"
            class="seleList"
          >
            <li class="sele-item">
              {{ item.name }}
              <i
                class="el-icon-delete-solid ico-del"
                @click="delTeacher(index, item.userId)"
              ></i>
            </li>
          </ul>
        </el-row>
      </el-col>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn1"
        >确 定</el-button
      >
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * teacherRole  String 学员角色
 *   Array 已选学员
 */
import { getSysAccountList } from "@/api/system/account";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    lessTeacher: {
      default: () => {
        return [];
      },
      type: Array,
    },
    courseInfo: {
      default: () => {},
      type: Object,
    },
    entryType: {
      default: "",
      type: Boolean,
    },
  },
  watch: {
    lessTeacher: {
      handler(val) {
        // 转换一下目的是为了不被同步
        this.multipleSelectionAll = val;
        this.$nextTick(()=>{
        this.openDialog();
        })
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "prev, pager, next, jumper",
        total: 0,
      },
      inputVal: "",
      select: "1",
      multipleSelectionAll: [], // 所有选中的数据包含跨页数据
      multipleSelection: [], // 当前页选中的数据
      idKey: "userId", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
      departmentList: [],
    };
  },
  methods: {
    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getUserTeacher();
    },

    // 窗口打开回调函数
    openDialog() {
      this.inputVal = "";
      this.select = "1";
      this.pagination.currentPage = 1;
      this.multipleSelectionAll = Object.assign([], this.lessTeacher);
      this.getUserTeacher();
      this.changePageCoreRecordData();
    },

    // 删除学员
    delTeacher(index, id) {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      this.multipleSelectionAll.splice(index, 1);
      // 标识当前行的唯一键的名称
      let idKey = this.idKey;
      let selectAllIds = id;
      for (var i = 0; i < this.tableData.length; i++) {
        if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.teaRef.toggleRowSelection(this.tableData[i], false);
        }
      }
    },

    // 获取学员用户列表
    async getUserTeacher() {
      const res = await getSysAccountList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount:true,
        loginName: this.select === "2" ? this.inputVal : "",
        name: this.select === "1" ? this.inputVal : "",
      });
      this.tableData = res.body.list.map((i) => {
        return {
          ...i,
          userId: i.id,
        };
      });
      this.pagination.total = res.body.total;
      setTimeout(() => {
        this.setSelectRow();
      }, 200);
    },

    handleSelection(val) {
      this.multipleSelection = val;
      this.changePageCoreRecordData();
    },

    // el-table表格勾选函数
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 关闭
    close() {
      this.$emit("close");
    },

    // 分页
    handleSizeChange(val) {
      this.changePageCoreRecordData();
      this.pagination.pageSize = val;
      this.getUserTeacher();
    },
    handleCurrentChange(val) {
      this.changePageCoreRecordData();
      this.pagination.currentPage = val;
      this.getUserTeacher();
    },

    // 保存
    saveRoleForm() {
      this.$nextTick(() => {
        // this.changePageCoreRecordData();
        if ( this.multipleSelectionAll && this.multipleSelectionAll.length <= 0) {
          window.$msg("无勾选数据", 2);
          return;
        }
        this.$emit("editStuData", this.multipleSelectionAll);
      });
    },

    // 设置选中的方法
    setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return;
      }
      // 标识当前行的唯一键的名称
      let idKey = this.idKey;
      let selectAllIds = [];
      this.multipleSelectionAll.forEach((row) => {
        selectAllIds.push(row[idKey]);
      });
      for (let i = 0; i < this.tableData.length; i++) {
        if (selectAllIds.indexOf(this.tableData[i][idKey]) >= 0) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.teaRef.toggleRowSelection(this.tableData[i], true);
        }
      }
    },

    // 记忆选择核心方法
    changePageCoreRecordData() {
      // 标识当前行的唯一键的名称
      let idKey = this.idKey;
      let that = this;
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.multipleSelection;
        return;
      }
      // 总选择里面的key集合
      let selectAllIds = [];
      this.multipleSelectionAll.forEach((row) => {
        selectAllIds.push(row[idKey]);
      });
      let selectIds = [];
      // 获取当前页选中的id
      this.multipleSelection.forEach((row) => {
        selectIds.push(row[idKey]);
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (selectAllIds.indexOf(row[idKey]) < 0) {
          that.multipleSelectionAll.push(row);
        }
      });
      let noSelectIds = [];
      // 得到当前页没有选中的id
      this.tableData.forEach((row) => {
        if (selectIds.indexOf(row[idKey]) < 0) {
          noSelectIds.push(row[idKey]);
        }
      });
      noSelectIds.forEach((id) => {
        if (selectAllIds.indexOf(id) >= 0) {
          for (let i = 0; i < that.multipleSelectionAll.length; i++) {
            if (that.multipleSelectionAll[i][idKey] === id) {
              // 如果总选择中有未被选中的，那么就删除这条
              that.multipleSelectionAll.splice(i, 1);
              break;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
/deep/ .el-table td {
  padding: 10px 0 !important;
}
.seleRow {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
  padding-left: 25px;
}
.seleNum {
  color: #fff;
  border-radius: 50%;
  padding: 1px 8px;
  background: #cccccc;
}
.seleList {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 20px;
}
.ico-del {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
  font-size: 15px;
  &:hover {
    color: #f5c319;
  }
}
/deep/ thead {
  .el-table-column--selection {
    .cell {
      display: none;
    }
  }
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 430px;
    margin-right: 38px;
  }
}
</style>
