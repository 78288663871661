<template>
<el-drawer
  title="明细详情"
  :size="size1"
  :visible.sync="drawer"
  :direction="direction"
  :before-close="handleClose"
  >
  <div style="margin-left: 20px">
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>学员列表</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="tableData"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          style="max-height: calc(100vh - 270px); overflow-y: auto"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '状态'">
               <el-tag v-if="scope.row.state==1" type="success">启用</el-tag>
               <el-tag v-else type="warning">停用</el-tag>
              </template>
              <template v-else-if="item.label === '用户类型'">
              <span v-if="scope.row.identities.length !== 0">
                <el-tag
                  style="margin-left: 5px"
                  type="info"
                  v-for="item in scope.row.identities"
                  :key="item.id"
                  >{{ item.name }}
                </el-tag>
              </span>
              <span v-else>--</span>
            </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
  </el-drawer>
</template>
<script>

import TextButton from "@/components/TextButton";
import {getSysAccountList} from "@/api/system/account";
// 默认展示列

const baseColSetting = [
  {
    label: "登录账号",
    prop: "loginName",
    state: true,
    align: "center",
  },
  {
    label: "姓名",
    prop: "name",
    state: true,
    align: "center",
  },
  {
    label: "状态",
    prop: "state",
    state: false,
    align: "center",
  },
  {
    label: "用户类型",
    prop: "identities",
    state: true,
    align: "center",
  },
  {
    label: "创建时间",
    prop: "createTime",
    state: false,
    align: "center",
  },
];
export default {
  props: {
     drawer: {
      default: false,
      type: Boolean,
    },
    courseInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: { TextButton },

  data() {
    return {
      size1:'800px',
      currentId:'',
      tableKey: 1,
      item: {},
      title: "",
      tableData: [],
      size: "default",
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      direction: 'rtl',
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },

  created() {
    this.getListHandler();
  },
  methods: {
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    async getListHandler() {
      let responseData = await getSysAccountList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        courseId: this.courseInfo.id,
        // ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    handleClose(){
      this.$emit('close')
    },
  
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },

    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/container.scss";
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
