import { render, staticRenderFns } from "./add.vue?vue&type=template&id=73bc2756&scoped=true"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"
import style0 from "./add.vue?vue&type=style&index=0&id=73bc2756&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73bc2756",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/frontend_coduck_manage_sbGx/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73bc2756')) {
      api.createRecord('73bc2756', component.options)
    } else {
      api.reload('73bc2756', component.options)
    }
    module.hot.accept("./add.vue?vue&type=template&id=73bc2756&scoped=true", function () {
      api.rerender('73bc2756', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TrainCourse/add.vue"
export default component.exports