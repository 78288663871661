<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false">
    <div slot="title"
        class="dia-tit">
      <i></i>
      <span v-text="curriculumTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="课程名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入课程名称（不可重名）" maxlength="16"></el-input>
      </el-form-item>
      <el-form-item label="课程昵称：">
        <el-input v-model="formData.nick" placeholder="请输入课程昵称（展示给购课学员）" maxlength="64"></el-input>
      </el-form-item>
      <el-form-item label="课程系列："  prop="series">
        <el-select style="width:100%" v-model="formData.series" placeholder="请选择课程系列">
          <el-option
            v-for="item in cascaderList"
            :key="item.id"
            :label="item.courseGoodsName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveCategory()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
/**
 * 弹出框-使用封装
 * @attribute show 弹出框状态
 * @attribute editData 根据有无 editData数据 动态渲染 dialog弹出框 数据
 * @function close 自定义弹窗关闭事件
 */
import { addCourse, editCourse , courseCatalog} from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editData: {
      default: () => {
        return {}
      },
      type: Object
    },
  },

  // 监控表单变化，清除验证
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },

  data () {
    return {
      cascaderList:[],
      curriculumTitle: '',
      formData: {
      },
      rules: {
        title: [
          { required: true, message: '课程名称不能为空', trigger: 'blur' },
          { max: 50, message: '字数限制在50个字内', trigger: 'blur' }
        ],
        // type: [{ required: true, message: '请选择课程类型', trigger: 'blur' }],
        series: [{ required: true, message: '请选择课程系列', trigger: 'blur' }],
      },
      categoryList: [],
      typeList: [{
        value: '常规',
        label: '常规'
      }, {
        value: '双师',
        label: '双师'
      }, {
        value: '试听',
        label: '试听'
      }, {
        value: '培训',
        label: '培训'
      }]
    }
  },
  created() {
    this.courseCatalog()
  },
  methods: {
    async courseCatalog () {
      const res = await courseCatalog({
        pageNum:1,
        pageSize:1000,
        needCount:true
      })
      this.cascaderList = res.body.list
    },
    openDialog () {
      this.formData = {}
      if (this.editData !== null) {
        this.curriculumTitle = '编辑课程'
        this.formData = { ...this.editData }
      } else {
        this.curriculumTitle = '新增课程'
      }
    },

    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 保存
    saveCategory () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.editData !== null) {
            editCourse({
              id: this.formData.id,
              title: this.formData.title,
              nick: this.formData.nick,
              type: '培训',
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            addCourse({
              title: this.formData.title,
              nick: this.formData.nick,
              type: '培训',
              courseGoodsId: this.formData.series
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('添加成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";
</style>
